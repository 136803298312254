<template>
    <div>
        <div class="product-header">
            <a-row :gutter="32">
                <a-col :span="12">
                    <div div class="product-header-text">
                        <div class="product-header-text-title">CheerMaaS 多云PaaS治理平台</div>
                        <div class="product-header-text-line"></div>
                        <div class="product-header-text-content">
                            我们不制造中间件，但可以管理你使用的中间件
                            <br />
                            支持开源和商业版中间件类型
                            <br />
                            平台化管理，统一入口的中台化服务模式
                        </div>
                    </div>
                </a-col>
                <a-col :span="12">
                    <div class="product-header-img">
                        <img src="@/assets/product/cheerMaaS/banner.png" width="600px" height="570px" />
                    </div>
                </a-col>
            </a-row>
        </div>
        <div style="clear: both"></div>
        <div class="title">功能架构</div>
        <div class="framework">
            <img src="@/assets//product/cheerMaaS/framework.png" width="100%" height="100%" />
        </div>
        <div class="title">功能特性</div>
        <div class="function">
            <a-row :gutter="[52, 32]">
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon1.png" :width="82" :height="82" />
                        <div>
                            <span>框架强大</span>
                            <p>强大易用的管理框架</p>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon2.png" :width="82" :height="82" />
                        <div>
                            <span>支持全面</span>
                            <p>适配开源商业版国产化</p>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon3.png" :width="82" :height="82" />
                        <div>
                            <span>安全可靠</span>
                            <p>高效稳定的基础服务集合</p>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <br />
            <a-row :gutter="[52, 32]">
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon4.png" :width="82" :height="82" />
                        <div>
                            <span>集群多样</span>
                            <p>可扩展,可伸缩,高可用的集群</p>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon5.png" :width="82" :height="82" />
                        <div>
                            <span>适配管理</span>
                            <p>强大的资源管理和适配</p>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="function-list">
                        <img src="@/assets/product/cheerMaaS/icon6.png" :width="82" :height="82" />
                        <div>
                            <span>适配云环境</span>
                            <p>功能可裁剪,适于云环境部署</p>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="title">价值优势</div>
        <div class="advantage">
            <div class="advantage-left">
                <div v-for="(item, index) in advantageLeftData" :key="index" class="choose-item">
                    <div>
                        <span class="choose-item-spot" />
                        <span class="choose-item-left-title">{{ item['title'] }}</span>
                    </div>
                    <div class="choose-item-left-text">{{ item['text'] }}</div>
                </div>
            </div>
            <div class="advantage-right">
                <div v-for="(item, index) in advantageRightData" :key="index" class="choose-item">
                    <div>
                        <span class="choose-item-right-title">{{ item['title'] }}</span>
                        <span class="choose-item-spot" />
                    </div>
                    <div class="choose-item-right-text">{{ item['text'] }}</div>
                </div>
            </div>
        </div>
        <div style="clear: both"></div>
        <br />
    </div>
</template>

<script>
export default {
    name: 'CheerOcean',
    data() {
        return {
            advantageLeftData: [
                { title: '性能卓越', text: '领先的云原生技术' },
                { title: '功能完备', text: '支持多种中间件类型' },
                { title: '支持最新技术', text: '兼容多种开源框架' }
            ],
            advantageRightData: [
                { title: '介质小巧', text: '更适于容器云环境部署' },
                { title: '安全可靠', text: '支持多种国密算法' },
                { title: '支持国产生态', text: '适配主流国产的操作系统和数据库' }
            ]
        }
    }
}
</script>

<style scoped>
/* 统一标题css */
.title {
    width: 100%;
    height: 76px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 76px;
    margin: 50px 0 30px 0;
}
/* 头部背景图 */
.product-header {
    width: 100%;
    background: #ebf5ff;
    float: left;
}
.product-header-text {
    width: 600px;
    height: 380px;
    margin: 140px 40px 60px 100px;
    float: left;
    background: #ffffff;
    box-shadow: 0px 0px 54px 0px #ddeeff;
}
.product-header-text-title {
    width: 100%;
    height: 60px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: 500;
    color: #333333;
    line-height: 79px;
    margin: 30px 0 0 30px;
}
.product-header-text-line {
    width: 94px;
    height: 10px;
    margin: 20px 0 20px 30px;
    background: #409eff;
}
.product-header-text-content {
    width: 100%;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 48px;
    margin: 20px 0 20px 30px;
}
.product-header-img {
    margin-top: 60px;
    margin-left: 30px;
}
/* 功能架构图 */
.framework {
    width: 80%;
    height: 520px;
    margin: 0 auto;
}
/* 功能特性 */
.function {
    width: 80%;
    margin: 0 auto;
}
/* 功能列表 */
.function-list {
    width: 100%;
    float: left;
    margin-left: 28px;
}
.function-list img {
    float: left;
    margin-left: 8%;
}
.function-list div {
    float: left;
    margin-left: 12px;
}
.function-list div span {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 46px;
    margin-top: 5px;
    display: inline-block;
}
.function-list div p {
    font-size: 14px;
    color: #333333;
}
/* 价值优势 */
.advantage {
    width: 80%;
    height: 552px;
    margin: 0 auto;
    background-image: url('../../../assets//product/cheerMaaS/footer.png');
    background-position: 51% 0; /*这个是按从左往右，从上往下的百分比位置进行调整*/
    background-size: 552px 552px;
    background-repeat: no-repeat; /*还有repeat-x,y等*/
}
/* 定位左边 */
.advantage-left {
    float: left;
    text-align: left;
    margin-top: 120px;
    margin-left: 30px;
}
/* 定位在右边 */
.advantage-right {
    float: right;
    text-align: right;
    margin-top: 120px;
    margin-right: 30px;
}
.choose-item {
    margin-bottom: 80px;
}
.choose-item-spot {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #409eff;
    border-radius: 12px;
}
/* 左边文件 */
.choose-item-left-title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 39px;
    margin-left: 12px;
}
.choose-item-left-text {
    width: 200px;
    font-size: 16px;
    color: #333333;
    margin-left: 24px;
}
/* 右边文字 */
.choose-item-right-title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 39px;
    margin-right: 12px;
}
.choose-item-right-text {
    width: 200px;
    font-size: 16px;
    color: #333333;
    margin-right: 24px;
}
</style>
