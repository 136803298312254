<template>
    <div>
        <div class="product-header">
            <a-row :gutter="32">
                <a-col :span="12">
                    <div class="product-header-text">
                        <div class="product-header-text-title">CheerVM 备份治理平台</div>
                        <div class="product-header-text-line"></div>
                        <div class="product-header-text-content">
                            成为支持智能数据管理的最可信任的备份解决方案提供商
                            <br />
                            提供面向未来的企业超可用性云智能数据管理平台
                        </div>
                    </div>
                </a-col>
                <a-col :span="12">
                    <div class="product-header-img">
                        <img src="@/assets/product/cheerVM/banner.png" width="600px" height="570px" />
                    </div>
                </a-col>
            </a-row>
        </div>
        <div style="clear: both"></div>
        <div class="title">功能架构</div>
        <div class="framework">
            <img src="@/assets//product/cheerVM/framework.png" width="100%" height="100%" />
        </div>
        <div class="title">功能特性</div>
        <div class="function">
            <a-row :gutter="[120, 32]">
                <a-col :span="6">
                    <div class="function-list">
                        <img src="@/assets/product/cheerVM/icon1.png" :width="80" :height="80" />
                        <div>对停机和数据丢失零容忍</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="function-list">
                        <img src="@/assets/product/cheerVM/icon2.png" :width="80" :height="80" />
                        <div>保护备份数据免受勒索软件的侵害</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="function-list">
                        <img src="@/assets/product/cheerVM/icon3.png" :width="80" :height="80" />
                        <div>满足合规要求并实现保留目标</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="function-list">
                        <img src="@/assets/product/cheerVM/icon4.png" :width="80" :height="80" />
                        <div>克服和存储人员限制，实现快速恢复</div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="title">价值优势</div>
        <div class="advantage">
            <a-row :gutter="32">
                <a-col :span="12">
                    <div class="advantage-left">
                        <div v-for="(item, index) in advantageLeftData" :key="index" class="choose-item">
                            <div>
                                <span class="choose-item-spot" />
                                <span class="choose-item-title">{{ item['title'] }}</span>
                            </div>
                            <div class="choose-item-text">{{ item['text'] }}</div>
                        </div>
                    </div>
                    <div class="advantage-right">
                        <div v-for="(item, index) in advantageRightData" :key="index" class="choose-item">
                            <div>
                                <span class="choose-item-spot" />
                                <span class="choose-item-title">{{ item['title'] }}</span>
                            </div>
                            <div class="choose-item-text">{{ item['text'] }}</div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="12">
                    <div class="advantage-img">
                        <img src="@/assets//product/cheerVM/footer.png" width="100%" height="100%" />
                    </div>
                </a-col>
            </a-row>
        </div>
        <br />
    </div>
</template>

<script>
export default {
    name: 'CheerOcean',
    data() {
        return {
            advantageLeftData: [
                { title: '开放', text: 'API，平台集成' },
                { title: '降低成本', text: '有效减低硬件及管理成本' },
                { title: '安全合规', text: '业务连续，数据加密，可恢复，可追溯' }
            ],
            advantageRightData: [
                { title: '多云多业态', text: '私有云，混合云，公有云，传统IT架构' },
                { title: '业务适配', text: '高效随云而动，有效使用管理，管理使用和保护同步' },
                { title: '使用管理方便', text: '多租户，自服务，使用维护简单明了，管理自动化' }
            ]
        }
    }
}
</script>

<style scoped>
/* 统一标题css */
.title {
    width: 100%;
    height: 76px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 76px;
    margin: 50px 0 30px 0;
}
/* 头部背景图 */
.product-header {
    width: 100%;
    background: #ebf5ff;
    float: left;
}
.product-header-text {
    width: 600px;
    height: 380px;
    margin: 140px 40px 60px 100px;
    float: left;
    background: #ffffff;
    box-shadow: 0px 0px 54px 0px #ddeeff;
}
.product-header-text-title {
    width: 100%;
    height: 60px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: 500;
    color: #333333;
    line-height: 79px;
    margin: 30px 0 0 30px;
}
.product-header-text-line {
    width: 94px;
    height: 10px;
    margin: 20px 0 20px 30px;
    background: #409eff;
}
.product-header-text-content {
    width: 100%;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 48px;
    margin: 20px 0 20px 30px;
}
.product-header-img {
    margin-top: 60px;
    margin-left: 30px;
}
/* 功能架构图 */
.framework {
    width: 70%;
    height: 480px;
    margin: 0 auto;
}
/* 功能特性 */
.function {
    width: 80%;
    margin: 0 auto;
}
/* 功能列表 */
.function-list {
    width: 100%;
    height: 250px;
    margin: 0 auto;
    background: #ffffff;
    text-align: center;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px;
}
.function-list div {
    padding: 20px;
    line-height: 28px;
    color: #333333;
    text-align: left;
}
/* 价值优势 */
.advantage {
    width: 80%;
    height: 500px;
    margin: 0 auto;
}
/* 定位左边 */
.advantage-left {
    float: left;
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
}
/* 定位在右边 */
.advantage-right {
    float: left;
    margin-top: 30px;
    margin-left: 60px;
}
/* 列表样式 */
.choose-item {
    margin-bottom: 80px;
}
.choose-item-spot {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #409eff;
    border-radius: 12px;
}
.choose-item-title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 39px;
    margin-left: 12px;
}
.choose-item-text {
    width: 200px;
    height: 30px;
    font-size: 16px;
    color: #333333;
    margin-left: 24px;
}
.advantage-img {
    width: 100%;
    height: 400px;
    margin-top: 30px;
    margin-right: 3rem;
}
</style>
xxx
