var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "product-header" },
      [
        _c(
          "a-row",
          { attrs: { gutter: 32 } },
          [
            _c("a-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "product-header-text" }, [
                _c("div", { staticClass: "product-header-text-title" }, [
                  _vm._v("CheerVM 备份治理平台"),
                ]),
                _c("div", { staticClass: "product-header-text-line" }),
                _c("div", { staticClass: "product-header-text-content" }, [
                  _vm._v(
                    " 成为支持智能数据管理的最可信任的备份解决方案提供商 "
                  ),
                  _c("br"),
                  _vm._v(" 提供面向未来的企业超可用性云智能数据管理平台 "),
                ]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "product-header-img" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerVM/banner.png"),
                    width: "600px",
                    height: "570px",
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticStyle: { clear: "both" } }),
    _c("div", { staticClass: "title" }, [_vm._v("功能架构")]),
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v("功能特性")]),
    _c(
      "div",
      { staticClass: "function" },
      [
        _c(
          "a-row",
          { attrs: { gutter: [120, 32] } },
          [
            _c("a-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerVM/icon1.png"),
                    width: 80,
                    height: 80,
                  },
                }),
                _c("div", [_vm._v("对停机和数据丢失零容忍")]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerVM/icon2.png"),
                    width: 80,
                    height: 80,
                  },
                }),
                _c("div", [_vm._v("保护备份数据免受勒索软件的侵害")]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerVM/icon3.png"),
                    width: 80,
                    height: 80,
                  },
                }),
                _c("div", [_vm._v("满足合规要求并实现保留目标")]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerVM/icon4.png"),
                    width: 80,
                    height: 80,
                  },
                }),
                _c("div", [_vm._v("克服和存储人员限制，实现快速恢复")]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "title" }, [_vm._v("价值优势")]),
    _c(
      "div",
      { staticClass: "advantage" },
      [
        _c(
          "a-row",
          { attrs: { gutter: 32 } },
          [
            _c("a-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "advantage-left" },
                _vm._l(_vm.advantageLeftData, function (item, index) {
                  return _c("div", { key: index, staticClass: "choose-item" }, [
                    _c("div", [
                      _c("span", { staticClass: "choose-item-spot" }),
                      _c("span", { staticClass: "choose-item-title" }, [
                        _vm._v(_vm._s(item["title"])),
                      ]),
                    ]),
                    _c("div", { staticClass: "choose-item-text" }, [
                      _vm._v(_vm._s(item["text"])),
                    ]),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "advantage-right" },
                _vm._l(_vm.advantageRightData, function (item, index) {
                  return _c("div", { key: index, staticClass: "choose-item" }, [
                    _c("div", [
                      _c("span", { staticClass: "choose-item-spot" }),
                      _c("span", { staticClass: "choose-item-title" }, [
                        _vm._v(_vm._s(item["title"])),
                      ]),
                    ]),
                    _c("div", { staticClass: "choose-item-text" }, [
                      _vm._v(_vm._s(item["text"])),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _c("a-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "advantage-img" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets//product/cheerVM/footer.png"),
                    width: "100%",
                    height: "100%",
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "framework" }, [
      _c("img", {
        attrs: {
          src: require("@/assets//product/cheerVM/framework.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }