var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "product-header" },
      [
        _c(
          "a-row",
          { attrs: { gutter: 32 } },
          [
            _c("a-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "product-header-text", attrs: { div: "" } },
                [
                  _c("div", { staticClass: "product-header-text-title" }, [
                    _vm._v("CheerMaaS 多云PaaS治理平台"),
                  ]),
                  _c("div", { staticClass: "product-header-text-line" }),
                  _c("div", { staticClass: "product-header-text-content" }, [
                    _vm._v(" 我们不制造中间件，但可以管理你使用的中间件 "),
                    _c("br"),
                    _vm._v(" 支持开源和商业版中间件类型 "),
                    _c("br"),
                    _vm._v(" 平台化管理，统一入口的中台化服务模式 "),
                  ]),
                ]
              ),
            ]),
            _c("a-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "product-header-img" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/banner.png"),
                    width: "600px",
                    height: "570px",
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticStyle: { clear: "both" } }),
    _c("div", { staticClass: "title" }, [_vm._v("功能架构")]),
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v("功能特性")]),
    _c(
      "div",
      { staticClass: "function" },
      [
        _c(
          "a-row",
          { attrs: { gutter: [52, 32] } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon1.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("框架强大")]),
                  _c("p", [_vm._v("强大易用的管理框架")]),
                ]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon2.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("支持全面")]),
                  _c("p", [_vm._v("适配开源商业版国产化")]),
                ]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon3.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("安全可靠")]),
                  _c("p", [_vm._v("高效稳定的基础服务集合")]),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c("br"),
        _c(
          "a-row",
          { attrs: { gutter: [52, 32] } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon4.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("集群多样")]),
                  _c("p", [_vm._v("可扩展,可伸缩,高可用的集群")]),
                ]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon5.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("适配管理")]),
                  _c("p", [_vm._v("强大的资源管理和适配")]),
                ]),
              ]),
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "function-list" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/product/cheerMaaS/icon6.png"),
                    width: 82,
                    height: 82,
                  },
                }),
                _c("div", [
                  _c("span", [_vm._v("适配云环境")]),
                  _c("p", [_vm._v("功能可裁剪,适于云环境部署")]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "title" }, [_vm._v("价值优势")]),
    _c("div", { staticClass: "advantage" }, [
      _c(
        "div",
        { staticClass: "advantage-left" },
        _vm._l(_vm.advantageLeftData, function (item, index) {
          return _c("div", { key: index, staticClass: "choose-item" }, [
            _c("div", [
              _c("span", { staticClass: "choose-item-spot" }),
              _c("span", { staticClass: "choose-item-left-title" }, [
                _vm._v(_vm._s(item["title"])),
              ]),
            ]),
            _c("div", { staticClass: "choose-item-left-text" }, [
              _vm._v(_vm._s(item["text"])),
            ]),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "advantage-right" },
        _vm._l(_vm.advantageRightData, function (item, index) {
          return _c("div", { key: index, staticClass: "choose-item" }, [
            _c("div", [
              _c("span", { staticClass: "choose-item-right-title" }, [
                _vm._v(_vm._s(item["title"])),
              ]),
              _c("span", { staticClass: "choose-item-spot" }),
            ]),
            _c("div", { staticClass: "choose-item-right-text" }, [
              _vm._v(_vm._s(item["text"])),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticStyle: { clear: "both" } }),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "framework" }, [
      _c("img", {
        attrs: {
          src: require("@/assets//product/cheerMaaS/framework.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }